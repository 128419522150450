<template>
  <v-app>
    <v-app-bar id="home-app-bar" app color="white" elevation="1" height="80">
      <v-img
        :src="require('@/assets/finman-logo.png')"
        contain
        max-width="128"
        width="100%"
      />

      <v-spacer />

      <!-- <div>
        <v-tabs class="hidden-sm-and-down" optional>
          <v-tab
            v-for="(item, i) in items"
            :key="i"
            :to="{ name: item.name }"
            :exact="item.name === 'Home'"
            :ripple="false"
            active-class="text--primary"
            class="font-weight-bold"
            min-width="96"
            text
          >
            {{ item.title }}
          </v-tab>
        </v-tabs>
      </div> -->

      <!-- <v-app-bar-nav-icon class="hidden-md-and-up" @click="drawer = !drawer" /> -->
    </v-app-bar>

    <!-- <v-navigation-drawer
      color="white"
      fixed
      height="100%"
      overlay-color="secondary"
      overlay-opacity=".8"
      temporary
      v-bind="$attrs"
      v-on="$listeners"
      v-model="drawer"
    >
      <v-img
        :src="require('@/assets/finman-logo.png')"
        contain
        max-width="128"
        width="100%"
        class="ma-4"
      />
      <v-list color="white" shaped>
        <v-list-item
          v-for="item in items"
          :key="item.name"
          :to="{ name: item.name }"
          :exact="name === 'Home'"
          color="primary"
        >
          <v-list-item-content>
            <v-list-item-title v-text="item.title" />
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer> -->

    <v-main>
      <section id="hero">
        <v-img
          :min-height="minHeight"
          :src="require('@/assets/home-hero.jpg')"
          class="white--text"
          gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
        >
          <v-container class="fill-height px-4 py-12">
            <v-responsive
              class="d-flex align-center"
              height="100%"
              max-width="700"
              width="100%"
            >
              <h1 class="font-weight-bold mb-4">
                FİN-MAN EĞİTİME HOŞ GELDİNİZ!
              </h1>

              <p>
                Uzaktan Eğitim Portalı, zamandan ve mekândan tamamen bağımsız
                bir şekilde öğrencinin ve eğitmenin bir araya gelme zorunluluğu
                olmaksızın internet erişimi olan herhangi bir yerden eğitimlerin
                yapıldığı yenilikçi bir eğitim sistemidir. Sanal sınıf
                sistemiyle sunulan canlı dersler, katılımcılara etkileşimli ve
                dinamik bir öğrenme süreci sunmaktadır.
              </p>

              <div
                :class="
                  $vuetify.breakpoint.smAndDown
                    ? 'flex-column align-start'
                    : 'align-center'
                "
                class="d-flex flex-wrap"
              >
                <v-btn class="primary" large href="http://oys.finman.com.tr">
                  GİRİŞ YAP
                </v-btn>
              </div>
            </v-responsive>
          </v-container>
        </v-img>
      </section>
    </v-main>

    <v-footer id="home-footer" color="primary" dark>
      <v-container fluid>
        <v-row>
          <v-col class="text-center text-md-right" cols="12">
            Copyright &copy; 2020. FİN-MAN A.Ş.
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: null,
    items: [
      {
        title: "Ana Sayfa",
        name: "Home"
      },
      {
        title: "Eğitimler",
        name: "Courses"
      },
      {
        title: "İletişim",
        name: "Contact"
      }
    ]
  }),

  computed: {
    minHeight() {
      //const height = this.$vuetify.breakpoint.mdAndUp ? "100vh" : "50vh";
      const height = "100vh";

      return `calc(${height} - ${this.$vuetify.application.top}px)`;
    }
  }
};
</script>

<style lang="sass">
#home-app-bar
  .v-tabs-slider
    max-width: 24px
    margin: 0 auto

    .v-tab > .v-tab:hover
      &::before
        display: none
</style>
